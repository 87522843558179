//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

.op-file-picker
  &--scrollable-content
    overflow-y: auto
    // If there a list in the content that provides tooltips on there items,
    // we need enough space to display those tooltips
    min-height: $spot-spacing-5

  @media #{$spot-mq-mobile}
    &.spot-modal
      min-height: calc(#{var(--app-height)} - 5rem)

  &--modal-body
    @media #{$spot-mq-desktop}
      height: calc(#{var(--app-height)} / 2)
      max-height: 32rem

  &--info
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 100%
    text-align: center
    color: var(--fgColor-muted)

    & &-icon
      width: 9.5rem
      height: 9.5rem
      font-size: 5.5rem
      margin-bottom: $spot-spacing-0_25
