.wp-timeline
  &--grid-element
    position: absolute
    top: 0
    padding-top: 5px
    height: 100%
    border-right: 1px solid #dddddd5e

    &_highlight
      border-right: 1px solid var(--borderColor-default)

  &--non-working-day
    background-color: #f5f5f5
