/*-- copyright
 * OpenProject Costs Plugin
 *
 * Copyright (C) 2009 - 2015 the OpenProject Foundation (OPF)
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU General Public License
 * version 3.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program; if not, write to the Free Software
 * Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 *
 */

.costs.costTypes
  .separator
    margin-left: -0.2em

table.list.members
  .form--text-field.-tiny
    min-width: 60px

.budget.details .attributes-key-value--value p
  margin-bottom: 0

.progress-bar .inner-progress.done
  background-color: var(--display-red-bgColor-muted)

.budget-row-template,
.subform-row-template
  display: none
