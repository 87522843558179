//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

@import "app/spot/styles/sass/common/icon"

$menu-item-line-height: 30px
$arrow-left-width: 40px

@mixin main-menu-hover
  background: var(--main-menu-bg-hover-background)
  color: var(--main-menu-hover-font-color)
  border: 1px solid var(--main-menu-hover-border-color)

@mixin main-menu-selected
  background: var(--main-menu-bg-selected-background)
  color: var(--main-menu-selected-font-color)
  border: 1px solid var(--main-menu-bg-selected-border)

.main-menu
  width: var(--main-menu-width)
  min-width: var(--main-menu-width)
  overflow-x: hidden
  border-right-style: solid
  border-right-width: var(--main-menu-border-width)
  border-right-color: var(--main-menu-border-color)
  background-color: var(--main-menu-bg-color)

  #menu-sidebar
    +allow-vertical-scrolling
    height: calc(100vh - var(--header-height))
    position: relative
    @include styled-scroll-bar

    // Fixed heights to allow inner scrolling
    .menu_root.closed,
    .menu_root > li.open,
    wp-query-select,
    .searchable-menu,
    .searchable-menu--search-container,
    .main-menu--children > li.partial:only-child
      height: 100%

    .main-menu--children
      // 10px spacing
      height: calc(100% - (var(--main-menu-item-height) + 10px))
      overflow: auto
      @include styled-scroll-bar

  a:not(.Button):focus
    color: var(--main-menu-font-color)

  ul
    margin: 0
    padding: 0

    // -------------------- ALL menu items ---------------------------
    li
      float: none
      list-style-type: none
      margin: 0
      padding: 0
      white-space: nowrap
      position: relative
      min-height: 23px

      &.ng-leave
        @include animation(0.5s fade-out)

      [class*="-menu-item"]
        display: flex
        align-items: center

    // -------------------- MAIN menu items ---------------------------
    li a
      // work around due to dom manipulation on document: ready:
      // this isn't scoped to .main-item-wrapper to avoid flickering
      padding-left: 12px

      &.toggler
        // explicitly reset to zero to avoid selector precedence problems
        padding-left: 0

    .main-menu--children li a:not(.Button)
      // children have no icon so we need to push them right.
      padding-left: 24px

  a:not(.Button)
    text-decoration: none
    line-height: var(--main-menu-item-height)
    position: relative
    height: var(--main-menu-item-height)
    color: var(--main-menu-font-color)
    font-family: var(--body-font-family)
    font-weight: normal
    font-size: var(--main-menu-font-size)
    font-style: normal
    padding: 0 12px

    &:hover
      text-decoration: none

  .toggler
    width: 40px
    height: var(--main-menu-item-height)
    overflow: hidden
    display: flex
    justify-content: center
    align-items: center

  .icon-time
    background: none
    padding: 0

.main-item-wrapper,
.main-menu .main-menu-item
  display: flex

  .toggler:hover
    @include main-menu-hover

  a:not(.Button)
    border: 1px solid transparent

    &.selected, &.selected + a
      @include main-menu-selected

      &:hover
        @include main-menu-hover

    &:hover, &:focus, &:active
      @include main-menu-hover

      & ~ .toggler
        @include main-menu-hover

  a:not(.Button):not(.toggler)
    @extend .small-12

  a:not(.Button):not(:only-child):first-of-type
    flex: 0 0 calc(100% - 40px)
    max-width: calc(100% - 40px)

// -------------------- CHILD menu items ---------------------------
.main-menu--children
  display: none
  padding: 10px 0
  width: 100%

  &.unattached
    border-top: 1px solid #ddd

  li
    &:hover
      // simultaneously hover all menu item anchor tags
      > a
        color: var(--main-menu-bg-hover-background)

    > a
      &.selected, &.selected
        @include main-menu-selected

      &:hover, &:focus, &:active
        @include main-menu-hover

.main-menu--children-menu-header
  padding: 10px 10px 0 10px
  height: calc(var(--main-menu-item-height) + 10px)

.main-menu--arrow-left-to-project
  display: inline-block
  width: $arrow-left-width
  float: left
  border-radius: 3px
  padding-left: 14px
  padding-right: 14px
  border: 1px solid transparent

  &:hover, &:focus, &:active
    @include main-menu-hover

a.main-menu--parent-node
  border: 1px solid transparent
  display: inline-block
  padding: 0 11px 0 11px
  font-size: var(--main-menu-font-size)
  font-weight: var(--base-text-weight-bold)
  width: calc(100% - #{$arrow-left-width})
  height: var(--main-menu-item-height)
  line-height: var(--main-menu-item-height)
  border-radius: 3px
  color: var(--main-menu-font-color)

  &:hover, &:focus, &:active
    @include main-menu-hover

// logic for showing either parent or child menu
.main-menu
  ul.menu_root
    &.closed
      li
        display: none

      > li.open
        display: list-item

        .main-item-wrapper
          display: none

        // Avoid that the original menu item is shortly shown before it is overwritten by
        // the parent menu entry with the toggler
        [class*="-menu-item"] .icon2,
        [class*="-menu-item"] .icon2 ~ .menu-item--title
          visibility: hidden

        .main-menu--children
          display: block

          li
            display: list-item

    &.open
      > li
        display: list-item

        .main-menu--children-menu-header
          display: none

.hidden-navigation
  .main-menu
    width: var(--main-menu-folded-width)
    min-width: var(--main-menu-folded-width)

    ul
      &.menu_root
        > li
          .main-item-wrapper
            a:not(.Button):not(:only-child)
              @extend .small-12

          .ellipsis
            text-overflow: clip
            -o-text-overflow: clip
            -ms-text-overflow: clip

          .toggler
            display: none

  #sidebar,
  .main-menu--children
    display: none

#sidebar
  margin: 30px 0 0 0

#sidebar, #menu-sidebar .sidebar
  padding: 0 17px 0 17px
  width: auto
  color: var(--main-menu-font-color)
  font-family: var(--body-font-family)
  font-style: normal

  a:not(.Button), a:not(.Button):link
    &:not(.searchable-menu--item-link):not(.searchable-menu--category-icon)
      color: var(--main-menu-font-color)
      display: inline
      position: static
      text-decoration: underline

  a:hover
    text-decoration: underline

  label
    color: var(--main-menu-font-color)
    @include text-shortener
    max-width: 80%
    vertical-align: top

  ul
    border: none
    overflow-x: hidden

    li
      border: none

    li a
      padding: 0

.main-menu--segment-header
  color: var(--main-menu-fieldset-header-color)
  text-transform: uppercase
  padding-left: var(--hierarchy-span-width)
  font-size: 0.7rem

#main-menu ul ul.main-menu--children ul.pages-hierarchy
  .tree-menu--hierarchy-indicator
    color: var(--main-menu-font-color)

  .tree-menu--item
    &.-selected
      background: var(--main-menu-bg-selected-background)

      .tree-menu--title
        color: var(--main-menu-selected-font-color)

    &:hover
      background: var(--main-menu-bg-hover-background)

      .tree-menu--title
        color: var(--main-menu-hover-font-color)
        text-decoration: none

// Resizer & toggle styles
.main-menu--resizer
  background: none
  height: 100%
  width: 0.25rem
  position: fixed
  display: flex
  align-items: center
  left: calc(var(--main-menu-width) - 0.25rem)
  z-index: 1
  cursor: col-resize

  .main-menu--navigation-toggler
    position: relative
    // Needed for Safari due to the fixed position of the parent element
    -webkit-transform: translateZ(0)
    cursor: pointer
    margin-left: -0.75rem
    color: var(--main-menu-font-color)

    > .collapse-menu,
    > .expand-menu
      display: none

    &.open:hover
      > .resize-handle
        display: none

      > .collapse-menu
        display: block

    &:not(.open)
      left: 1rem
      color: var(--accent-color)

      > .resize-handle
        display: none

      > .expand-menu
        display: block

  &:hover
    border-right: 0.125rem solid var(--main-menu-border-color)

    .main-menu--navigation-toggler.open
      color: var(--main-menu-border-color)

// Badges for menu items such as "EXPERIMENTAL" or "BETA"
$badge_offset: 4px

.main-item--badge
  font-size: 10px
  position: relative
  top: -$badge_offset
  text-transform: uppercase
  margin-left: 5px
  font-weight: var(--base-text-weight-bold)
  font-style: italic

.main-menu-toggler
  background: transparent
  color: var(--main-menu-font-color)
  border: none


#main-menu-toggle
  .icon-close
    display: none

.content-overlay
  display: none
